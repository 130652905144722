import { NameValuePair } from "./api/name-value-pair";

/** These are the roles that match the API side. */
export enum Roles {
  GlobalAdmin = "GlobalAdmin",
  TenantAdmin = "TenantAdmin",
  AuthorizingOfficial = "AuthorizingOfficial",
  ProgramOwner = "ProgramOwner",
  SystemOwner = "SystemOwner",
  ProjectManager = "ProjectManager",
  CisoIssm = "CisoIssm",
  IssoSecurityPractitioner = "IssoSecurityPractitioner",
  Assessor = "Assessor",
}

/** Other constants that can be used. */
export abstract class Constants {

  /** The names and friendly values for all roles. */
  static readonly RoleNames: NameValuePair[] = [
    { name: Roles.GlobalAdmin, value: "Global Administrator" },
    { name: Roles.TenantAdmin, value: "Tenant Administrator" },
    { name: Roles.AuthorizingOfficial, value: "Authorizing Official" },
    { name: Roles.ProgramOwner, value: "Program Owner" },
    { name: Roles.SystemOwner, value: "System Owner" },
    { name: Roles.ProjectManager, value: "Project Manager" },
    { name: Roles.CisoIssm, value: "CISO / ISSM" },
    { name: Roles.IssoSecurityPractitioner, value: "ISSO / Security Practitioner" },
    { name: Roles.Assessor, value: "Assessor" },
  ];

  // Colors for charts and graphs and stuff.
  static readonly Color_BigStatus_NotStarted: string = '#8c9199';
  static readonly Color_BigStatus_InProgress: string = '#B94F2D';
  static readonly Color_BigStatus_Finalizing: string = '#DF9F1F';
  static readonly Color_BigStatus_Complete: string = '#33867F';
}

/** The types of event notification messages. */
export enum EventNotificationMessageTypes {
  default = "default",
  error = "error",
  warning = "warning",
}
import { Injectable, OnDestroy, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable, Subject, interval, take, takeUntil, tap } from 'rxjs';
import { SsoExchange } from '../api/identity/sso-exchange';
import { HttpClient } from '@angular/common/http';
import { RefreshTokenExchange } from '../api/identity/refresh-token-exchange';
import { CookieService } from 'ngx-cookie-service';
import { TokenDataResponse } from '../api/identity/token-data-response';

@Injectable({
  providedIn: 'root'
})

// The purpose of this service is to accept the redirect token from a login
// page, connect to the identity service and load the tokens, then move on
// in to the application normally.

export class TokenAuthService implements OnDestroy {

  // Cookies!!
  private cookieService = inject(CookieService);

  constructor() { }

  ngOnDestroy(): void {
    // Clean up subscriptions.
    this.exchangeEntryObservable.complete();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // This is used for unsubscription destruction. https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription/41177163#41177163
  private ngUnsubscribe = new Subject<void>();


  /* This section handles the process of exchanging the one-time redirect code for
     the real tokens for a user. 
     ------------------------------------------------------------------------------- */

  // The observable when the token entry is performed.
  private exchangeEntryObservable = new Subject<SsoExchange>();
  private exchangeEntryHttp = inject(HttpClient);

  /** Performs the API call to exchange the entry token and receive the real deal. */
  exchangeEntry(redirect: string, subject: string): Observable<SsoExchange> {

    // Make the call to the API.
    this.exchangeEntryHttp.post<SsoExchange>(
      environment.endpoints.api.identityV1 + '/sso/entry', { redirect: redirect, sub: subject }
    ).subscribe({
      next: (response) => {
        if (environment.idp.debug) {
          console.log('sso/entry response ↓');
          console.log(response);
        }
        // Successful?
        if (response && response.success) {
          this.exchangeEntryObservable.next(response);
        } else {
          this.exchangeEntryObservable.error(response.message);
        }
        this.exchangeEntryObservable.complete();
      },
      error: (err) => {
        if (environment.idp.debug) {
          console.error('sso/entry ERROR ↓');
          console.error(err);
        }
        this.exchangeEntryObservable.error(err.message);
        this.exchangeEntryObservable.complete();
      }
    });

    return this.exchangeEntryObservable;
  }


  /* This section deals with storage and retrieval of the JWT tokens for the current
     user.
     ------------------------------------------------------------------------------- */

  /** Perform the actual "login" with the user's tokens. */
  loginWithTokens(response: TokenDataResponse) {

    // Store these in the location specified in the configuration - session or local.
    // Tokens stored in sessionStorage will not be present if the user opens a new browser tab.
    // Tokens stored in localStorage will persist across browser tabs until they are all closed.
    if (environment.portal.useSessionStorageForTokens) {
      // Put them in sessionStorage.
      sessionStorage.setItem('_t', response.token);
      sessionStorage.setItem('_te', response.tokenExpiration);
    } else {
      // Put them in localStorage.
      localStorage.setItem('_t', response.token);
      localStorage.setItem('_te', response.tokenExpiration);
    }

    // Refresh always goes in the cookie. Mmmm.... cookie.
    this.cookieService.set('_r', response.refreshToken);
    this.cookieService.set('_re', response.refreshExpiration);

    this.jwt = null;

    if (environment.idp.debug) {
      console.log('Now logged in with a token (...' + response.token.slice(-10) + ').');
    }
  }


  /** True if there is a current, unexpired Bearer token. */
  isLoggedIn(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  /** Returns the current authorization token to put as the Bearer for any API calls. */
  public getAuthorizationToken(): string | null {
    return this.getToken();
  }

  /** Returns the user's current role claim, based on the token. */
  public getUserRole(): string | null {
    if (this.getJwtObject()) {
      return this.getJwtObject()[environment.portal.security.tokenClaimForRole];
    }
    return null;
  }

  /** Returns the user's email address from the token. */
  public getUserEmail(): string | null {
    if (this.getJwtObject()) {
      return this.getJwtObject()[environment.portal.security.tokenClaimForEmail];
    }
    return null;
  }

  /** Returns the user's current assignment ID from the token. */
  public getUserAssignmentId(): string | null {
    if (this.getJwtObject()) {
      return this.getJwtObject()[environment.portal.security.tokenClaimForAssignment];
    }
    return null;
  }

  /** Returns the user's current tenant ID from the token. */
  public getUserTenantId(): string | null {
    if (this.getJwtObject()) {
      return this.getJwtObject()[environment.portal.security.tokenClaimForTenant];
    }
    return null;
  }

  /** Returns the token as a JWT object so that you can get the pieces out you need. */
  private getJwtObject() {
    // Return the cache if it's there.
    if (!this.jwt) { 
      let t = this.getToken();
      if (t) {
        this.jwt = JSON.parse(atob(t.split(".")[1]));
      }
    }
    return this.jwt;
  }
  private jwt?: any | null;

  /** Gets the current, unexpired Bearer token. */
  private getToken(): string | null {

    // Use these to store the actual items.
    var token: string | null;
    var date: Date | null = this.getTokenExpiration();

    // First get the values based on the config.
    if (environment.portal.useSessionStorageForTokens) {
      token = sessionStorage.getItem('_t');
    } else {
      token = localStorage.getItem('_t');
    }

    // They gotta exist...
    if (token) {
      var now = new Date();
      if (date && (date > now)) {
        return token;
      } else {
        if (environment.idp.debug) {
          console.log('getToken() - Token existed (...' + token.slice(-10) + '), but was expired. date > now ' + date + ' > ' + now);
        }
      }
    } else {
      if (environment.idp.debug) {
        console.log('getToken() - No token exists.');
      }
    }

    // Made it here? We have no valid token.
    return null;
  }

  /** Gets the datetime the token expires. */
  private getTokenExpiration(): Date | null {
    var expiration: string | null;

    // Get it.
    if (environment.portal.useSessionStorageForTokens) {
      expiration = sessionStorage.getItem('_te');
    } else {
      expiration = localStorage.getItem('_te');
    }

    // If it's there.
    if (expiration) {
      var date = new Date(expiration);
      return date;
    }

    return null;
  }

  /** Gets the current refresh token. */
  public getRefreshToken(): string | null {
    var refreshToken: string | null;
    var date: Date | null = this.getRefreshTokenExpiration();

    // Get the cookie.
    refreshToken = this.cookieService.get('_r');

    // They gotta exist...
    if (refreshToken && date) {
      var now = new Date();
      if (date && (date > now)) {
        return refreshToken;
      }
    }

    // Made it here? We have no valid token.
    return null;
  }

  /** Gets the datetime the refresh token expires. */
  private getRefreshTokenExpiration(): Date | null {
    var expiration: string | null;

    // Get it.
    expiration = this.cookieService.get('_re');

    // If it's there.
    if (expiration) {
      var date = new Date(expiration);
      return date;
    }

    return null;
  }

  /** The signout stuff. */
  private signoutHttp = inject(HttpClient);

  /** Signs out of the portal entirely. */
  endSession(): Observable<any> {
    if (environment.idp.debug) {
      console.log('Ending session.');
    }
    // Have the API signout.
    return this.signoutHttp
      .post(environment.endpoints.api.identityV1 + '/auth/revoke', undefined)
      .pipe(
        tap((response) => {
          this.clearTokens();
          if (environment.idp.debug) {
            console.log('Token revokation successful.');
          }
        })
      );
  }

  /** Clears out the tokens for a logout. */
  private clearTokens() {
    // Remove all of the session/local storage items.
    if (environment.portal.useSessionStorageForTokens) {
      sessionStorage.removeItem('_t');
      sessionStorage.removeItem('_te');
    } else {
      localStorage.removeItem('_t');
      localStorage.removeItem('_te');
    }

    // No more cookies. :(
    this.cookieService.delete('_r');
    this.cookieService.delete('_re');

    this.jwt = null;
  }

  // Stores the ID of the user that's currently logged in. This is used for the refresh token activity.
  private userId: string | undefined;

  /** Does the things to start the session. */
  beginSession(id: string) {
    this.userId = id;
    // Since we have a refresh token, we want to start a timer that runs until just before the 
    // main token expiration. The purpose of the refresh token is to hang around in a cookie and
    // allow the user to remain authenticated for a while longer.
    const expires = this.getTokenExpiration();
    if (environment.idp.debug) {
      console.log('TokenAuthService is beginning session; refresh expires: ' + expires);
    }
    if (expires) {
      // Figure out the time that's 1 minute before the expiration.
      const tokenTimeout = expires?.getTime() - (Date.now() + (60 * 1000));
      
      if (environment.idp.debug) {
        console.log('TokenAuthService will refresh at the interval: ' + tokenTimeout);
      }
  
      interval(tokenTimeout)
        .pipe(
          tap((t) => console.log('Token refresh execution #: ' + t))
        )
        .subscribe(t => {
          if (environment.idp.debug) {
            console.log('TokenAuthService executing at the timeout interval ' + t + '.');
          }
          this.consumeRefreshToken().subscribe(r => {
            if (environment.idp.debug) {
              console.log('TokenAuthService received token refresh response ↓');
              console.log(r);
            }
            this.loginWithTokens(r);
          })
        });
    } else {
      if (environment.idp.debug) {
        console.error('Cannot begin session because there is not a refresh token expiration!');
      }
    }
  }

  // The observable when the refresh token is consumed.
  private consumeRefreshHttp = inject(HttpClient);

  private consumeRefreshToken(): Observable<RefreshTokenExchange> {
    const refreshToken = this.getRefreshToken();
    if (environment.idp.debug) {
      console.log('TokenAuthService is consuming refresh token, POSTing to /auth/refresh.');
    }
    return this.consumeRefreshHttp
      .post<RefreshTokenExchange>(environment.endpoints.api.identityV1 + '/auth/refresh',  // URL
        { refresh: refreshToken, id: this.userId },  // body
        { headers: { 'Content-Type': 'application/json' } }  // headers
      );
  }

}

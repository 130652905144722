import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable, Subject, shareReplay, switchMap, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MyInfoResponse } from '../api/identity/my-info-response';
import { MyAssignmentsResponse } from '../api/identity/my-assignments-response';
import { TokenAuthService } from './token-auth.service';
import { Roles } from '../portal-constants';
import { UserSettings } from '../api/identity/user-settings';
import { SetUserSettingResponse } from '../api/identity/set-user-setting-response';
import { UserInfo } from '../api/identity/user-info';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService
  implements OnDestroy {

  private tokenSVC = inject(TokenAuthService);

  constructor() { }

  ngOnDestroy(): void {
    // Clean up subscriptions.
    this.getUserInfoObservable.complete();
    this.getUserAssignmentsObservable.complete();
  }


  /** Performs the actual user signout. */
  userLogout() {
  }

  /** Sends this user to the login page, with or without a message. */
  userRedirect(signoutMessage?: string) {
    var endpoint = environment.endpoints.ui.login + (signoutMessage ? '?message=' + encodeURIComponent(signoutMessage) : '');
    console.log('Logout is redirecting to: ' + endpoint);
    window.location.href = endpoint;
  }

  /** Switches a user's current assignment for another one. */
  userSwitchAssignment(userId: string, assignment: string, refresh: string) {
    var endpoint = environment.endpoints.ui.login + '/assignment/' + assignment + '/' + userId + '/' + refresh;
    console.log('Switching assignments at: ' + endpoint);
    window.location.href = endpoint;
  }

  /* This section contains the stuff for getting user information. */
  private getUserInfoObservable = new Subject<MyInfoResponse>();
  private http = inject(HttpClient);
  // This is used to cache the user for a little bit.
  private currentUser$!: Observable<MyInfoResponse>;

  /** Returns the current user's information, potentially cached from any other request. */
  getUserInfo(): Observable<MyInfoResponse> {
    // No cached version somewhere?
    if (!this.currentUser$) {
      // Ensure that this cache updates every now and then.
      const cacheRefresher$ = timer(0, environment.portal.myInfoCacheMillis);

      // Make the call, using the pipe and switchMap to ensure there isn't a race condition
      // or anything silly like that.
      this.currentUser$ = cacheRefresher$.pipe(
        switchMap(_ => this.internalGetUserInfo()),
        shareReplay(1)
      );
    }

    // Whatever be here is what we be returnin'.
    return this.currentUser$;
  }

  // Returns the current user info back to the caller.
  private internalGetUserInfo(): Observable<MyInfoResponse> {

    // Make the HTTP call to get the data for this user.
    this.http.get<MyInfoResponse>(
      environment.endpoints.api.identityV1 + '/me/info'
    ).subscribe({
      next: (response) => {
        if (environment.idp.debug) {
          //console.log('me/info response ↓');
          //console.log(response);
        }
        // Successful?
        if (response && response.success) {
          this.getUserInfoObservable.next(response);
        } else {
          this.getUserInfoObservable.error(response.message);
        }
      },
      error: (err) => {
        if (environment.idp.debug) {
          console.error('me/info ERROR ↓');
          console.error(err);
        }
      }
    });

    // Send back the subscription.
    return this.getUserInfoObservable;
  }


  /* This section contains the stuff for getting user information. */
  private getUserAssignmentsObservable = new Subject<MyAssignmentsResponse>();

  /** Retrieves all user assignments for this user. */
  getUserAssignments() {

    // Call the API to get all assignments.
    this.http.get<MyAssignmentsResponse>(
      environment.endpoints.api.identityV1 + '/me/assignments'
    ).subscribe({
      next: (response) => {
        if (environment.idp.debug) {
          console.log('me/assignments response ↓');
          console.log(response);
        }
        // Successful?
        if (response && response.success) {
          this.getUserAssignmentsObservable.next(response);
        } else {
          this.getUserAssignmentsObservable.error(response.message);
        }
      },
      error: (err) => {
        if (environment.idp.debug) {
          console.error('me/assignments ERROR ↓');
          console.error(err);
        }
      }
    });

    // Send back the subscription.
    return this.getUserAssignmentsObservable;
  }

  /** Helper to get the user's current assignment Id. */
  getUserAssignmentId(): string {
    return this.tokenSVC.getUserAssignmentId()!;
  }

  /** Helper to get the current user's email. */
  getUserEmail(): string | null {
    return this.tokenSVC.getUserEmail();
  }

  /** Helper to get the current user's active role. */
  getUserRole(): Roles {
    return (<any>Roles)[this.tokenSVC.getUserRole()!];
  }

  /** Helper to determine if the current user is a tenant administrator. */
  isTenantAdmin() {
    return Roles.TenantAdmin === this.getUserRole();
  }

  /** Returns the user's assigned tenant Id, if applicable. */
  getUserTenantId(): string | null {
    return this.tokenSVC.getUserTenantId();
  }

  /** Saves a user's setting. */
  saveUserSetting(
    currentUser: UserInfo,
    settings: UserSettings) {
console.log('saving! ' + JSON.stringify(settings));
    // Send this to the backend.
    return this.http.patch<SetUserSettingResponse>(
      environment.endpoints.api.identityV1 + '/me/settings', { 
        forUserAssignmentId: currentUser.currentAssignment?.id,
        userSettings: settings
       });
  }

}
